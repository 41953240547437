import React from 'react'
import tw, {css } from 'twin.macro'
import parse from 'style-to-object';

//
import { Heading } from '~storybook/blocks'

export const ParagraphHeading = ({ 
  uuid,
  fieldHeading, 
  fieldHeadingType, 
  fieldAnchorLinkId, 
  fieldBreakpoints ,
  fieldCss,
  fieldBackground,
  fieldBackgroundStyle,
  fieldDisplayMarginLeft,
  fieldDisplayMarginRight,
  fieldDisplayTextAlignment,
  fieldDisplayPaddingBottom,
  fieldDisplayPaddingTop,
  fieldDisplayPaddingLeft,
  fieldDisplayPaddingRight
}) => {

  const bkPointStyles = {
    Block: css`${tw`block`}`,

    Small: css`${tw`hidden sm:block`}`,
    Medium: css`${tw`hidden md:block`}`,
    Large: css`${tw`hidden lg:block`}`,
    XLarge: css`${tw`hidden xl:block`}`,

    FromSmall: css`${tw`hidden sm:block`}`,
    FromMedium: css`${tw`hidden md:block`}`,
    FromLarge: css`${tw`hidden lg:block`}`,
    FromXLarge: css`${tw`hidden xl:block`}`,

    TillSmall: css`${tw`block sm:hidden`}`,
    TillMedium: css`${tw`block md:hidden`}`,
    TillLarge: css`${tw`block lg:hidden`}`,
    TillXLarge: css`${tw`block xl:hidden`}`,
  }

  const anchorID = fieldAnchorLinkId ? fieldAnchorLinkId : uuid
  const block_CSS = []
  const heading_CSS = []


  // PARAGRAPH DISPLAY OPTIONS START

  if(fieldBackground) {
    block_CSS['backgroundColor'] = '#' + fieldBackground
  }

  if(fieldBackgroundStyle == "fullwidth") {
    block_CSS['marginLeft'] = '-3000px'
    block_CSS['marginRight'] = '-3000px'
    block_CSS['paddingLeft'] = '3000px'
    block_CSS['paddingRight'] = '3000px'
  }

  if(fieldDisplayMarginLeft) {
    heading_CSS['marginLeft'] = fieldDisplayMarginLeft
  }

  if(fieldDisplayMarginRight) {
    heading_CSS['marginRight'] = fieldDisplayMarginRight
  }

  if(fieldDisplayPaddingBottom) {
    heading_CSS['paddingBottom'] = fieldDisplayPaddingBottom
  }

  if(fieldDisplayPaddingTop) {
    heading_CSS['paddingTop'] = fieldDisplayPaddingTop
  }

  if(fieldDisplayPaddingLeft) {
    heading_CSS['paddingLeft'] = fieldDisplayPaddingLeft
  }

  if(fieldDisplayPaddingRight) {
    heading_CSS['paddingRight'] = fieldDisplayPaddingRight
  }

  if(fieldDisplayTextAlignment == "center") {
    block_CSS['text-align'] = 'center' 
  }
  if(fieldDisplayTextAlignment == "left") {
    block_CSS['textAlign'] = 'left' 
  }
  if(fieldDisplayTextAlignment == "right") {
    block_CSS['textAlign'] = 'right' 
  }

  // PARAGRAPH DISPLAY OPTIONS STOP

  return (
    <section className={`bg-white-500 mb-8 relative`} id={anchorID} css={[fieldBreakpoints && bkPointStyles?.[fieldBreakpoints]]} style={block_CSS}>
      <Heading text={fieldHeading} level={fieldHeadingType} uuid={uuid} style={heading_CSS} />
    </section>
  )
}

export default ParagraphHeading
